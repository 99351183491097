import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"
import GatsbyImage from "gatsby-image"

const Image = (props) => {

  const { src, alt, zoom, shape, size, ...gatsbyImageProps } = props

  return (
    <div className={clsx("image", props.className,
      zoom && `zoom-${zoom}`,
      shape && `shape-${shape}`,
    )} tabIndex={props.tabIndex} style={{ width: size+"px", height: size+"px" }}>
      { src
        ? <img src={src} alt={alt} />
        : <GatsbyImage alt={alt} { ...gatsbyImageProps } />
      }
    </div>
  )
}

Image.propTypes = {
  className: (
    PropTypes.string
  ),
  src: (
    PropTypes.string
  ),
  alt: (
    PropTypes.string
  ),
  zoom: PropTypes.oneOf([
    "mobile",
  ]),
  shape: PropTypes.oneOf([
    "circle"
  ]),
  tabIndex: (
      PropTypes.number
  ),
  size : (
      PropTypes.string
  )
}

export default Image
