import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

const Icon = (props) => (
  <span className={clsx("icon", props.className,
    props.type && `type-${props.type}`,
    props.rotate && `rotate-${props.rotate}`,
  )}>{ props.children }</span>
)

Icon.propTypes = {
  className: (
    PropTypes.string
  ),
  type: PropTypes.oneOf([
    "red-oak", "arrow", "mail", "phone", "quotes",
    "development", "coaching", "prototyping", "mainframe",
    "puzzle", "clock", "dialog", "magnifier", "order", "people",
    "number-1", "number-2", "number-3", "number-4",
    "number-5", "number-6", "number-7", "number-8",
    "instagram", "twitter", "xing", "youtube", "youtube-red", "laptop",
    "vacation", "money", "person", "bulb", "pencil", "adobe", "office",
    "box"
  ]),
  rotate: PropTypes.oneOf([
    90, 180, 270,
  ]),
}

export default Icon
