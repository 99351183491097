import React from "react"
import { Link } from "gatsby"
import Image from "../../image"
import Icon from "../../icon";
import Heart from "../../../images/benefiz/whiteheart.svg"




const Footer = () => {
  return (
    <footer className={"footer"}>
      <div className={"main"}>
        <div className={"sitemap"}>
          <div className={"navigation"}>
            <h2>Navigation</h2>
            <ul>
              <li>
                <Link to="/">Startseite</Link>
              </li>
              <li>
                <Link to="/ueber-uns">Über uns</Link>
              </li>
              <li>
                <Link to="/leistungen">Leistungen</Link>
              </li>
              <li>
                <Link to="/karriere">Karriere</Link>
              </li>
            </ul>
          </div>
          <div className={"support"}>
            <h2>Support</h2>
            <ul>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
              <li>
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                <Link to="/datenschutz">Datenschutzhinweise</Link>
              </li>
              <li style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <Link to="/benefiz-open">
                  <Image className={"heart"} src={Heart} alt="Benefiz" size={"11"}/><span>Benefiz</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className={"customers"}>
            <h2>Unsere Kunden</h2>
            <ul>
              <li>
                <a href={"https://www.volkswagenag.com"}
                   rel={"noreferrer"}
                   target={"_blank"}
                   tabIndex={0}
                   title={"Volkswagen AG"}
                >
                  <Image
                      src={"/assets/customers/volkswagen-ag.svg"}
                      alt={"Volkswagen AG"}
                  />
                </a>
              </li>
              <li>
                <a href={"https://www.ewe.com"}
                   rel={"noreferrer"}
                   target={"_blank"}
                   tabIndex={0}
                   title={"EWE AG"}
                >
                  <Image
                      src={"/assets/customers/ewe-ag.svg"}
                      alt={"EWE AG"}
                  />
                </a>
              </li>
              <li>
                <a href={"http://www.braunschweig.de"}
                   rel={"noreferrer"}
                   target={"_blank"}
                   tabIndex={0}
                   title={"Stadt Braunschweig"}
                >
                  <Image
                      src={"/assets/customers/stadt-braunschweig.svg"}
                      alt={"Stadt Braunschweig"}
                  />
                </a>
              </li>
              <li>
                <a href={"https://www.pipa-online.de/"}
                   rel={"noreferrer"}
                   target={"_blank"}
                   tabIndex={0}
                   title={"pipa - Beratung.Training"}
                >
                  <Image
                      src={"/assets/customers/pipa-beratung-training.svg"}
                      alt={"pipa - Beratung.Training"}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={"social"}>
          <div className={"media"}>
            <ul>
              <li>
                <a href={"https://www.instagram.com/redoakconsulting"}
                   target={"_blank"}
                   rel={"noreferrer"}
                   tabIndex={0}
                >
                  <Icon type={"instagram"} />
                </a>
              </li>
              <li>
                <a href={"https://twitter.com/red_oakies"}
                   target={"_blank"}
                   rel={"noreferrer"}
                   tabIndex={0}
                >
                  <Icon type={"twitter"} />
                </a>
              </li>
              <li>
                <a href={"https://www.xing.com/pages/redoakconsulting-servicesforit"}
                   target={"_blank"}
                   rel={"noreferrer"}
                   tabIndex={0}
                >
                  <Icon type={"xing"} />
                </a>
              </li>
              <li>
                <a href={"https://www.youtube.com/channel/UCLYEQhYShREWbCqzaDWghrg/videos"}
                   target={"_blank"}
                   rel={"noreferrer"}
                   tabIndex={0}
                >
                  <Icon type={"youtube"} />
                </a>
              </li>
            </ul>
          </div>
          <div className={"copyright"}>
            &copy;{` ${new Date().getFullYear()} `}
            Red Oak Consulting & Services for IT GmbH & Co. KG
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
